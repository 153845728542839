import $ from 'jquery';
import React from 'react';

class UnmuteRequestAlert extends React.Component {
  state = {
    sending: false,
    sent: false,
  };

  _sent = (event) => {
    if (this.state.sending) {
      return;
    }
    this.setState({sending: true});
    const params = {
      user_id: this.props.userId,
      team_id: this.props.teamId,
    };
    $.post('/team_unmute_requests', params).done(() => {
      this.setState({sending: false, sent: true});
    });
  };

  render() {
    return (
      <div className="alert alert-warning" role="alert" style={{marginBottom: 0}}>
        {'To post content you must be unmuted by a team administrator. '}
        {
          this.state.sending ? (
            <span className="loading-gif" />
          ): this.state.sent ? (
            <span>
              Request sent.
            </span>
          ): (
            <a className="cursor-pointer" onClick={this._sent}>
              Request to be unmuted.
            </a>
          )
        }
      </div>
    );
  }
}

export default UnmuteRequestAlert;
