import ReactDOM from 'react-dom';
import preloaded from '../src/preloaded.js';
import Alert from '../src/unmuteRequests/Alert.js';

const target = document.getElementById('UnmuteRequestAlert');
const targets = document.querySelectorAll('.UnmuteRequestAlert');
if (target) {
  ReactDOM.render(
    <Alert teamId={preloaded.team.id} userId={preloaded.current_user_id} />
  , target);
} else if (targets) {
  Array.from(targets).forEach( el => {
      console.log('el', el);
      ReactDOM.render(
        <Alert teamId={preloaded.team.id} userId={preloaded.current_user_id} />
      , el)
    })
}
